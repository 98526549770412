import { Link } from "gatsby"
import React, { Fragment } from "react"
import { Disclosure, Popover, Transition } from '@headlessui/react'

import SponsorLink from "../../components/promotions/sponsorLink"

import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  RedditIcon,
} from 'react-share'
// import { FeedbackFish } from '@feedback-fish/react'
import withLocation from "./withlocation"

// import Wrap from "./wrap"

// import "../../styles/footer.css"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Navigation = ({ navigation, location }) => {
  return (
    <div className="flex">
      {navigation.map((item) => (
        <React.Fragment key={item.name}>
          {!item.nested ? (
            <Link
              key={item.name}
              to={item.href}
              className={classNames(
                location.pathname.startsWith(item.href) || (item.activeNav && location.pathname.startsWith(item.activeNav)) ? 'bg-yellow-400' : 'hover:bg-yellow-400',
                'rounded-md cursor-pointer py-2 px-3 mr-1 text-sm font-medium flex items-center',
                item.button ? 'bg-yellow-500 hover:bg-yellow-500 text-gray-800' : 'text-gray-800'
              )}
            >
              {item.name}
              {item.nested ? (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-1 opacity-80 w-4 h-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
              ) : ''}
            </Link>
          ) : (
            <React.Fragment>
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={'rounded-md cursor-pointer py-2 px-3 mr-1 text-sm font-medium flex items-center text-gray-800 hover:bg-yellow-400'}
                    >
                      {item.name}
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-1 opacity-80 w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 left-0 mt-3 px-2 w-screen max-w-xs sm:px-0">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-1 bg-white p-2">
                            {item.links.map((l) => (
                              <Link
                                key={l.name}
                                to={l.href}
                                className={classNames(
                                  location.pathname.startsWith(l.href) ? 'bg-yellow-100' : 'hover:bg-yellow-100',
                                  'rounded-md cursor-pointer py-2 px-3 mr-1 text-sm font-medium flex items-center text-gray-800',
                                )}
                              >
                                {l.name}
                              </Link>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </React.Fragment>
          )}
        </React.Fragment>
      ))}
    </div>
  )

}


export default withLocation(Navigation)
