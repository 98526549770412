import { Link } from "gatsby"
import React from "react"

import SponsorLink from "../../components/promotions/sponsorLink"

import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  RedditIcon,
} from 'react-share'
// import { FeedbackFish } from '@feedback-fish/react'

// import Wrap from "./wrap"

// import "../../styles/footer.css"

const Footer = ({ }) => {
  return (
    <footer>
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl text-gray-800 text-sm">
        <div className="flex flex-wrap border-t border-gray-200 py-8">
          <div className="text-left">
            <div>
              <span className="inline">© {new Date().getFullYear()} Hive Index</span>{' '}
              by <a href="https://twitter.com/intent/follow?screen_name=foliofed" target="_blank" className="hover:underline">@foliofed</a>
              {/*<span className="block sm:inline">All rights reserved.</span>*/}
            </div>

            {/* Optional sponsorship link. Only shows if active sponsor for the site */}
            <SponsorLink location={'footer'} canHide={false}>
              {({ name, url, descriptor, onClick}) => (
                <div className="">
                  Sponsored by <a href={url} className="hover:underline" onClick={onClick} target="_blank" rel="sponsored nofollow">{name}</a>
                </div>
              )}
            </SponsorLink>
          </div>
          <div className="mt-2 sm:mt-0 sm:ml-auto flex flex-wrap">
            <Link to="/" className="opacity-75 hover:opacity-100 cursor-pointer">
              Home
            </Link>
            <span className="opacity-50 mx-0.5 sm:mx-2">•</span>
            <Link to="/submit/" className="opacity-75 hover:opacity-100 cursor-pointer">
              Add Community
            </Link>
            <span className="opacity-50 mx-0.5 sm:mx-2">•</span>
            <Link to="/partners/community/" className="opacity-75 hover:opacity-100 cursor-pointer">
              Advertise
            </Link>
            {/*<span className="opacity-50 mx-0.5 sm:mx-2">•</span>
            <Link to="/partners/traffic/" className="opacity-75 hover:opacity-100 cursor-pointer">
              Traffic
            </Link>*/}
            <span className="opacity-50 mx-0.5 sm:mx-2">•</span>
            <Link to="/blog/" className="opacity-75 hover:opacity-100 cursor-pointer">
              Blog
            </Link>
            <span className="opacity-50 mx-0.5 sm:mx-2">•</span>
            {/*<a href="mailto:contact@thehiveindex.com" target="_blank" rel="noopener" className="opacity-75 hover:opacity-100 cursor-pointer">
              Contact
            </a>
            <span className="opacity-50 mx-0.5 sm:mx-2">•</span>*/}
            {/*(<Link to="/terms-and-conditions/" className="opacity-75 hover:opacity-100 cursor-pointer">
              Terms
            </Link>
            <span className="opacity-50 mx-0.5 sm:mx-2">•</span>*/}
            <Link to="/privacy-policy/"  className="opacity-75 hover:opacity-100 cursor-pointer">
              Privacy
            </Link>
            <span className="opacity-50 mx-0.5 sm:mx-2">•</span>
            <a href="https://twitter.com/intent/follow?screen_name=thehiveindex" aria-label="Twitter" target="_blank" rel="noopener" className="opacity-75 hover:opacity-100 cursor-pointer">
              <TwitterIcon size={20} round={true} />
            </a>
            <span className="mx-0.5 sm:mx-1"></span>
            <a href="https://www.reddit.com/r/hiveindex/" target="_blank" aria-label="Reddit" rel="noopener" className="opacity-75 hover:opacity-100 cursor-pointer">
              <RedditIcon size={20} round={true} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )

}


export default Footer
