// import { FullStoryAPI } from 'react-fullstory';

// track an event
const trackEvent = (eventName, props) => {
  // GA
  if (typeof(window.gtag) !== "undefined"){
     window.gtag('event', eventName, props);
  }

  // plausible
  if (typeof(window.plausible) !== "undefined"){
    window.plausible(eventName, {
      'props': props
      // callback: () => console.info('Sent event'),
    });
  }

  // beam
  if (typeof(window.beam) !== "undefined"){
    window.beam(eventName); // no props yet?
  }

  // tinybird
  if (typeof(window.Tinybird) !== "undefined"){
    window.Tinybird.trackEvent(eventName, props);
  }

  // posthog
  if (typeof(window.posthog) !== "undefined"){
    window.posthog.capture(eventName, props);
  }
}

// // identify user to fullstory
// const identifyUser = (userConfig) => {
//   if (typeof(FullStoryAPI) !== "undefined" && userConfig){
//     const userId = `${userConfig.env.name}-${userConfig.identity.id}`
//     FullStoryAPI('identify', userId, {
//       environment: userConfig.env.name,
//       is_staff: userConfig.identity.is_staff,
//       is_beta: userConfig.identity.is_beta,
//       objective: userConfig.profile.objective,
//       subscription: userConfig.subscription && userConfig.subscription.key,
//     });
//   }
// }

// const deidentifyUser = () => {
//   if (typeof(FullStoryAPI) !== "undefined"){
//     try {
//       FullStoryAPI('anonymize') // ideally would use FullStoryAPI.anonymize() but it's not in the package yet
//     } catch (error) {
//       console.error("Tried FS anonymize but threw an error", error);
//     }
//   }
// }

export {
    trackEvent,
    // identifyUser,
    // deidentifyUser,
}