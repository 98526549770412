import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { localStorageAvailable } from "../../utils/localStorage"
import { trackEvent } from "../../utils/tracking"


// shows a sponsored link if there's an active promotion
export default function SponsorLink({children, location, canHide}) {
  const [hidden, setHidden] = useState(false);
  const data = useStaticQuery(graphql`
    query SponsorQuery {
      allGoogleSpreadsheetSourcePromotedSponsors(sort: { order: ASC, fields: [order] }, filter: {active: {eq: "TRUE"}}) {
        edges {
          node {
            key
            active
            name
            url
            descriptor
            cta
          }
        }
      }
    }
  `)
  const sponsorEdge = data && data.allGoogleSpreadsheetSourcePromotedSponsors.edges[0]; // should only really be 1 (if any)
  if (!sponsorEdge) return ''; // don't return anything if there's no active sponsor

  // if localstorage isn't available, don't query for sponsorship
  if (!localStorageAvailable()) return '';

  // if JUST hidden, don't show sponsorship
  if (hidden) return ''

  // if user has already hidden it, don't show it
  const sponsor = sponsorEdge.node;
  const localStorageKey = `sponsor-hidden-${location}-${sponsor.key}`; // use location so we hide in header only and not on rest of page
  if (localStorage.getItem(localStorageKey)) return ''; // if already hidden this one, remove forever

  // get the props to send to the child component
  var props = Object.assign({}, sponsor);

  // sponsorship url should include utm params
  if (sponsor.url.includes('?')){
    props.url = `${sponsor.url}&ref=thehiveindex.com&utm_source=thehiveindex.com&utm_campaign=sponsor`
  } else {
    props.url = `${sponsor.url}?ref=thehiveindex.com&utm_source=thehiveindex.com&utm_campaign=sponsor`
  }

  // action to track event on click
  props.onClick = () => {
    trackEvent("SponsorLinkClick", 
      Object.assign({
        'location': location,
      }, sponsor) // add all sponsor info, and also the location
    )
  }

  // hide action
  if (canHide){
    props.onHide = () => {
      localStorage.setItem(localStorageKey, true);
      setHidden(true);
    }
  }

  // otherwise return the children but with the data of the sponsor
  return (
    <React.Fragment>
      { children({...props}) }
    </React.Fragment>
  )
}