import React, { useState, useEffect } from 'react'

import ReactTooltip from 'react-tooltip';
import copy from 'copy-to-clipboard';

import withLocation from "./withlocation"
import { trackEvent } from "../../utils/tracking"

import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
  EmailShareButton,
  EmailIcon
} from 'react-share'

// TODO: add pinterest
// TODO: add copy paste

const ShareButtons = ({
  title, 
  location, 
  buttonBackground,
  openShareButtons,
  urlOverride,
}) => {
    const [showButtons, setShowButtons] = useState(openShareButtons || false);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
      setShowButtons(openShareButtons || false);
      setCopied(false);
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    const thisUrl = urlOverride || location.origin + location.pathname;
    const shareUrl = thisUrl + '?utm_source=sharebutton'
    const urlToCopy = thisUrl + '?utm_source=copybutton'


    const copyUrl = (url) => {
      if (!url) return;
      copy(url);
      setCopied(true);
      trackEvent("CopyUrl", {
        'title': title,
        'url': urlToCopy,
      });
    }

    const trackShare = (medium) => {
      trackEvent("ShareUrl", {
        'medium': medium,
        'title': title,
        'url': shareUrl,
      })
    }

    return(
      <div className="flex flex-wrap space-x-2 justify-center">
        {showButtons ? (
          <React.Fragment>
            <RedditShareButton url={shareUrl + '&utm_medium=reddit'} title={title} 
              onClick={() => trackShare('reddit')}
            >
              <RedditIcon  size={35} round={true} />
            </RedditShareButton>

            {/*<FacebookMessengerShareButton url={shareUrl + '&utm_medium=messenger'} title={title}
              onClick={() => trackShare('messenger')}
            >
                 <FacebookMessengerIcon  size={35} round={true}/>
             </FacebookMessengerShareButton>*/}

            <WhatsappShareButton url={shareUrl + '&utm_medium=whatsapp'} title={title}
              onClick={() => trackShare('whatsapp')}
            >
                 <WhatsappIcon  size={35} round={true}/>
             </WhatsappShareButton>

            <TelegramShareButton url={shareUrl + '&utm_medium=telegram'} title={title}
              onClick={() => trackShare('telegram')}
            >
                 <TelegramIcon  size={35} round={true}/>
            </TelegramShareButton>

            <TwitterShareButton url={shareUrl + '&utm_medium=twitter'} title={`${title} @thehiveindex`}
              onClick={() => trackShare('twitter')}
            >
                  <TwitterIcon  size={35} round={true} />
            </TwitterShareButton>

            <LinkedinShareButton url={shareUrl + '&utm_medium=linkedin'} 
              onClick={() => trackShare('linkedin')}
            >
              <LinkedinIcon  size={35} round={true}/>
            </LinkedinShareButton>

            <FacebookShareButton url={shareUrl + '&utm_medium=facebook'} title={title}
                onClick={() => trackShare('facebook')}
            >
              <FacebookIcon  size={35} round={true}/>
            </FacebookShareButton>


             {/*<EmailShareButton url={shareUrl + '&utm_medium=email'} title={title}
              onClick={() => trackShare('email')}
            >
                  <EmailIcon  size={35} round={true}/>
            </EmailShareButton>*/}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ReactTooltip id={`share-tooltip`} place="bottom" effect="solid" backgroundColor="black" textColor="white" />
            <div className={`p-3 rounded-full ${buttonBackground || 'bg-yellow-500'} text-white opacity-75 hover:opacity-100 cursor-pointer`}
              data-for={`share-tooltip`} data-tip={`Share this page`}
              onClick={() => setShowButtons(true)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
              </svg>
            </div>
          </React.Fragment>
        )}

        <ReactTooltip id={`copy-tooltip`} place="bottom" effect="solid" backgroundColor="black" textColor="white" />
        <div className={`p-3 rounded-full ${copied ? 'bg-green-500' : buttonBackground || 'bg-yellow-500'} text-white opacity-75 hover:opacity-100 cursor-pointer`}
          data-for={`copy-tooltip`} data-tip={copied ? 'Copied URL to clipboard' : `Copy URL`}
          onClick={() => copyUrl(urlToCopy)}
        >
          {copied ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
            </svg>
          )}
        </div>
        
      </div>
    )
}
export default withLocation(ShareButtons)