// util function to see if we can use localstorage
const localStorageAvailable = () => {
  try {
      localStorage.setItem('test', 'test');
      localStorage.removeItem('test');
      return true;
  } catch(e) {
      return false;
  }
}


export {
  localStorageAvailable
}