import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
// import Img from 'gatsby-image'

const Image = ({ fileName, alt, style, className, width, height, loading }) => {
  const { allImageSharp } = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          fluid(maxWidth: 1600) {
            originalName
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const image = allImageSharp.nodes.find(n => n.fluid.originalName === fileName);
  if (!image) return ''

  const fluid = image.fluid;

  // including this one makes it blurry initially but handles resizing well i think
  //<Img fluid={fluid} alt={alt} style={style} />

  return (
      <img src={fluid.src} className={className} alt={alt} style={style} width={width} height={height} loading={loading} />
  )
}

export default Image;