import React from 'react'
import { Location } from '@reach/router'

import { getParamsFromUrl } from '../../utils/urls'

const withLocation = ComponentToWrap => props => (
  <Location>
    {({ location, navigate }) => (
      <ComponentToWrap
        {...props}
        location={location}
        navigate={navigate}
        queryParams={getParamsFromUrl(location)}
      />
    )}
  </Location>
)

export default withLocation